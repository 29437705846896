<template>
	<div>
		<CBreadcrumb :items="links" style="margin-top: -28px;"/>
		<CRow>
			<CCol sm="12">
				<CCard>
    				<CCardBody>
						<CRow>
							<CCol sm="8">
								<h1 id="traffic" class="card-title mb-0">LOG SYSTEM</h1>
								<div class="large text-muted">DAFTAR LOG SYSTEM.</div>
							</CCol>
							<CCol sm="4" class="col align-self-center row justify-content-end">
								<CButton
									class="my-2 text-center"
									color="success"
									variant="outline"
									square
									size="sm"
									@click="generatePdf"
									>
									Generate PDF
								</CButton> &nbsp;
									
							</CCol>
						</CRow>	
						<CRow class="mt-3">
          					<CCol sm="3">
								<CInput
									type="date"
									label="Date From"
									placeholder=""
									description="Masukkan tanggal awal pencarian."
									:value.sync="start_date"
									required
								/>
							</CCol>
							<CCol sm="3">
								<CInput
									type="date"
									label="Date To"
									placeholder=""
									description="Masukkan tanggal akhir pencarian."
									:value.sync="end_date"
									required
								/>
							</CCol>
							<CCol sm="3">
								<CButton
									style="margin-top: 30px;"
									color="primary"
									variant="outline"
									square
									size="sm"
									v-on:click="apply()"
								>
								Apply
								</CButton> &nbsp;
								<CButton
									style="margin-top: 30px;"
									color="success"
									variant="outline"
									square
									size="sm"
									@click="filterRefresh"
								>
								Refresh Data
								</CButton>
							</CCol>
						</CRow>
					</CCardBody>
				</CCard>
				<CCard>
					<CCardBody>
						
						<CDataTable
							hover
							striped
							border
							small
							sorter
							itemsPerPageSelect
							:items="log"
							:fields="fields"
							:items-per-page="perPage"
							pagination
							:loading="isLoading"
						>
							<template #timestamp="{item}">
								<td align="left">
									<p>{{item.timestamp | formatDate}} {{item.timestamp | formatTime}}</p>
								</td>
							</template>
					
						</CDataTable>

						<vue-html2pdf
							:show-layout="true"
							:float-layout="false"
							:enable-download="true"
							:preview-modal="false"
							:paginate-elements-by-height="1400"
							filename="Log-system"
							:pdf-quality="2"
							:manual-pagination="true"
							pdf-format="a4"
							pdf-orientation="portrait"
							pdf-content-width="100%"
							@hasStartedGeneration="hasStartedGeneration()"
							@hasGenerated="hasGenerated($event)"
							:html-to-pdf-options="htmlToPdfOptions"
							ref="html2Pdf"
							class="d-none"
						>
							<section slot="pdf-content" id="content">
								<CRow class="my-3">
									<CCol md="4" class="m-auto" >
										<CCol md="12" >
											No. Dok :
										</CCol>
										<CCol md="12">
											Versi : 
										</CCol>
										<CCol md="12">
											Label : 
										</CCol>
									</CCol>
									<CCol md="4" class="d-flex justify-center">
										<h4 class="m-auto">List Log System</h4>
									</CCol>
									<CCol md="4" class="d-flex justify-center">
										<img src="@/assets/logo.png" style="height: 70px;" class="my-auto ml-auto mr-5" />
									</CCol>
								</CRow>
								<CDataTable
									hover
									striped
									border
									small
									sorter
									:items="log"
									:fields="fields"
									:loading="isLoading"
									class="mx-3"
								>
									<template #timestamp="{item}">
										<td align="left">
											<p>{{item.timestamp | formatDate}} {{item.timestamp | formatTime}}</p>
										</td>
									</template>
								</CDataTable>
							</section>
						</vue-html2pdf>
					</CCardBody>
				</CCard>
			</CCol>
		</CRow>
	</div>	
</template>

<script>
import http from '@/utils/http-common';
import TheTableHeader from '@/containers/TheTableHeader.vue'
import VueHtml2pdf from 'vue-html2pdf';

export default {
	name: 'Log System',
	components: { TheTableHeader, VueHtml2pdf },
	data () {
		return {
			start_date: this.date2Str(new Date(), 'yyyy-MM-dd'),
      		end_date: this.date2Str(new Date(), 'yyyy-MM-dd'),
			links: [
				{
					text: 'Home',
					href: '#/'
				}, 
				{
					text: 'Daftar Log System',
				}
			],
			log: [],
			fields: [
				'no', 
				{ key: 'timestamp', label: 'Date' }, 
				'description', 
				'action', 
				'affected_object',
			],
			currentPage: 1,
			perPage: 10,
			totalRows: 1,
			isLoading: true,
			htmlToPdfOptions: {
				margin: 0,
				filename: 'Log-System',
				'pdf-quality': '2',
				jsPDF: {
					unit: 'mm',
					format: 'a4',
					orientation: 'portrait'
				},
				html2canvas: {
					dpi: 300,
					letterRendering: true,
					useCORS: true
				}
			},
		}
	},
	paginationProps: {
		align: 'right',
		doubleArrows: false,
		previousButtonHtml: 'prev',
		nextButtonHtml: 'next'
	},
	methods: {
		generatePdf () {
			this.$refs.html2Pdf.generatePdf();
		},
		date(value, format) {
			return moment(String(value)).locale('Id').format(format);
		},
		date2Str(x, y) {
			const z = {
					M: x.getMonth() + 1,
					d: x.getDate(),
					h: x.getHours(),
					m: x.getMinutes(),
					s: x.getSeconds()
				};
				y = y.replace(/(M+|d+|h+|m+|s+)/g, function(v) {
				return ((v.length > 1 ? "0" : "") + z[v.slice(-1)]).slice(-2)
			});

			return y.replace(/(y+)/g, function(v) {
				return x.getFullYear().toString().slice(-v.length)
    		});
		},
	
		filterRefresh() {
			this.start_date = this.date2Str(new Date(), 'yyyy-MM-dd');
			this.end_date = this.date2Str(new Date(), 'yyyy-MM-dd');
			this.log = [];
			this.getLog();
		},
		apply(){
			this.log = [];
			this.getLog();
		},
		getLog(){
			var self = this
			let start;
			let end;
			return http.get('/log-users')
			.then(function (response) {
				// console.log(response.data.data)
				if(response.data.type != 'empty'){
					start = new Date(self.start_date);
					end = new Date(self.end_date);

					let start_format = start.getFullYear()+'-'+(start.getMonth()+1)+'-'+start.getDate()
					let end_format = end.getFullYear()+'-'+(end.getMonth()+1)+'-'+end.getDate()

					response.data.data.forEach(element => {
					
						let data_date = new Date(element.timestamp)
						let data_date_format = data_date.getFullYear()+'-'+(data_date.getMonth()+1)+'-'+data_date.getDate()

						if(start_format <= data_date_format && end_format >= data_date_format){
							self.log.push(element)
						}
					});
					// self.log = response.data.data;
					self.isLoading = false;
				}
				
				// console.log(self.data)
			}).catch(function (error) {
				console.log(error);
			});
		},
	},
	mounted: function(){
		this.getLog();
	}
}

</script>
